import { isEqual } from "lodash";
import { mapActions, mapMutations } from "vuex";
import { bus } from "@/main"
export default {
  data: () => ({
    selectedFilter: null
  }),
  computed: {
    currentFilter() {
      let currentFilter = [];
      if (Object.keys(this.$route.query)) {
        for (let query in this.$route.query) {
          let splitedQuery = (this.$route.query[query] && this.$route.query[query].includes(',')) ? this.$route.query[query].split(",") : [this.$route.query[query]];
          for (let queryValue of splitedQuery) {
            if(this[`${query}_list`] && this[`${query}_list`].length) {
              let filterItem = this[`${query}_list`].find(
                (el) => el.value === queryValue
              );
              if (filterItem) {
                filterItem.type = query;
                currentFilter.push(filterItem);
              }
            }
            
          }
        }
      }
      return currentFilter;
    },
    filter() {
      let filter = {};
      if (this.date_before) {
        filter.date_before = [this.date_before];
      }
      if (this.date_after) {
        filter.date_after = [this.date_after];
      }
      if (this.therapeutic_areas.length) {
        filter.therapeutic_areas = this.therapeutic_areas;
      }
      if (this.types.length) {
        filter.types = this.types;
      }
      if (this.type.length) {
        filter.type = this.type;
      }
      if (this.disease.length) {
        filter.disease = this.disease;
      }
      if (this.ordering.length) {
        filter.ordering = this.ordering;
      }
      return filter;
    },
    date_before: {
      get () {
        return this.$store.state.filter.date_before
      },
      set (value) {
        this.$store.commit('updateDateBefore', value)
      }
    },
    date_after: {
      get () {
        return this.$store.state.filter.date_after
      },
      set (value) {
        this.$store.commit('updateDateAfter', value)
      }
    },
    therapeutic_areas: {
      get () {
        return this.$store.state.filter.nosology
      },
      set (value) {
        this.$store.commit('updateNos', value)
      }
    },
    type: {
      get () {
        return this.$store.state.filter.type
      },
      set (value) {
        this.$store.commit('updateType', value)
      }
    },
    types: {
      get () {
        return this.$store.state.filter.types
      },
      set (value) {
        this.$store.commit('updateTypes', value)
      }
    },
    disease: {
      get () {
        return this.$store.state.filter.disease
      },
      set (value) {
        this.$store.commit('updateDis', value)
      }
    },
    ordering: {
      get () {
        return this.$store.state.filter.sort
      },
      set (value) {
        this.$store.commit('updateSort', value)
      }
    }
  },
  methods: {
    ...mapMutations(['updateDateBefore','updateDateAfter', 'updateNos', 'updateType', 'updateTypes', 'updateDis', 'updateSort']),
    ...mapActions(['clearFilter', 'clearDates']),
    buildQuery() {
      let query = {};
      let queryObj = {...this.$route.query}
      let filterQueryItems = ['ordering', 'disease', 'types', 'type', 'therapeutic_areas', 'date_before', 'date_after']
      for (let key of filterQueryItems) {
        delete queryObj[key]
      }
      for (let key in this.filter) {
        if(this.filter[key].filter(el => ((el !== "" && el !== '') && el !== null)).join(',')) {
          query[key] = this.filter[key].filter(el => ((el !== "" && el !== '') && el !== null)).join(',');
        }
      }
      Object.assign(query, queryObj) 
      if (isEqual(this.$route.query, query)) return;
      this.$router.replace({ query }).catch(() => {});
    },
    fillFilter() { 
      if (Object.keys(this.$route.query).length) {
        for (let query in this.$route.query) {
          this[query] = (this.$route.query[query] && this.$route.query[query].includes(',')) ? this.$route.query[query].split(",") : [this.$route.query[query]];
        }
      }
    },
    removeFilterItem(type, val) {
      let index = this[type].findIndex((el) => el === val);
      this[type].splice(index, 1);
    },
    openFilter() {
      this.filterOpened = true;
      bus.$emit('scrollLock', true)
    },
    closeFilter() {
      if (this.selectedFilter !== null) {
        this.selectedFilter = null;
        this.filterTitle = "Фильтр";
      } else {
        this.filterOpened = false;
        bus.$emit('scrollLock', false)
      }
    },
    selectFilter(id, title) {
      this.filterTitle = title;
      this.selectedFilter = id;
    },
  },
  mounted() {
    this.fillFilter();
  },
  beforeDestroy() {
    this.clearFilter()
  },
  watch: {
    therapeutic_areas(n,o) {
      if(o.length) {
        this.clearDates()
      }    
    },
    type(n,o) {
      if(o.length) {
        this.clearDates()
      }    
    },
    types(n,o) {
      if(o.length) {
        this.clearDates()
      }    
    },
    "$route": {
      handler: async function (n,o) {
        if(o?.path === n?.path) {
            await this.fillFilter();
        }
     
      },
      deep: true,
      immediate: true,
    },
  },
}